import axios from 'axios'

let serviceUrl = ''
const refreshAccessToken = async () => {
  return await axios({
    method: 'POST',
    url: 'https://sso-jwt-token-service-stable-v1.devops.svc.cluster.local/v1/sso_findep/get_new_token',
    data: {
      appJwt:  process.env['APP_JWT'] || "TYSON-ORIGINACION-ORQUESTA",
      serviceName:  process.env['SERVICE_NAME'] || "LOWCOST-ORIGINATION-ORCHESTRATOR"
    }
  });
}
switch (process.env.GATSBY_ACTIVE_ENV) {
  case 'production':
    serviceUrl = 'https://bff-origination-service.orquesta.tijuana.mesh-servicios-fnd.mx/v1'
    break
  case 'qa':
  case 'staging':
  case 'test':
    serviceUrl = '/v1'
    break
  default:
    serviceUrl = '/v1'
}

export const LandingV1Instance = axios.create({
  baseURL: serviceUrl,
  timeout: 90000
})


// Request interceptor for API calls
LandingV1Instance.interceptors.request.use(
  async config => {
    return config;
  },
  error => {
    Promise.reject(error)
});

// Response interceptor for API calls
LandingV1Instance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  console.log("LandingV4Instance", error)
});
