import React from 'react'

import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
import VerificacionComprobante from '../components/pages/VerificacionComprobante';
import GlobalFooterProvider from '../context/GlobalStateFooterContext';

class VerifocacionComprobantePage extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { pageContext } = this.props
        return (
            <GlobalFooterProvider>
            <LayoutsComponent
                layout={pageContext.layout}
                broker={pageContext.broker}
                companyName={pageContext.company}
                location={this.props.location}
                pageContext={this.props.pageContext}
                render={() => (
                    <>
                        <SEO title={pageContext.title} />
                        <VerificacionComprobante pageContext={pageContext} />
                    </>
                )}
            />
            </GlobalFooterProvider>
        )
    }
}

export default VerifocacionComprobantePage;
