
// import { css } from '@emotion/react'
import { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { TitleIcon, CardElevation, FileUpload } from '@findep/mf-landings-core'
import { Box, Typography, Grid, Button, Snackbar } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

import PublishIcon from '@material-ui/icons/Publish';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { VeryfiCompDom } from '../../services/LandingV4/verificacionComprobante.service'
import { navigate } from '../../helpers/queryNavigate';

import { root, styleTitle, text, btnContainer, rootBtn } from '../../styles/documents'
import { GlobalStateFooterContext } from '../../context/GlobalStateFooterContext';
import getTenant from '../../helpers/getTenant';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const VerificacionComprobante = ({ pageContext }) => {

    const { setIsDynamic, setTenantFooter } = useContext(GlobalStateFooterContext);    

    const isAltoPerfilBroker = pageContext.broker === 'CREDIALTO' || 'TECNO CREDITO' || 'KAHOOL CREDIT'
    const [generarError, setGeneralError] = useState(true);
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [errorService, setErrorService] = useState(false);
    const [flujo, setFlujo] = useState({});
    const [state, setState] = useState({ comprobanteDomicilio: '', selfi_domicilio: '' });

    const classes = useStyles();
    const { broker } = pageContext;

    
    useEffect(() => {
        const getTenantHelper = async () => {
            let localFlujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
            setFlujo(localFlujo)
            let id = localFlujo?.creditApplicationId
            //GET TENANT        
            const tenantValue = await getTenant(id)
            setIsDynamic(true)
            setTenantFooter(tenantValue)
        }

        getTenantHelper()
    }, [])
    
    useEffect(() => {
        console.log(pageContext)

        if (isAltoPerfilBroker) {
            state.comprobanteDomicilio ? setGeneralError(false) : setGeneralError(true)
        } else {
            if(state.comprobanteDomicilio && state.selfi_domicilio){
                setGeneralError(false)
            }else{
                setGeneralError(true)
            }
        }
    }, [state])

    const handleFile = async (file, flag) => {
        await setState({ ...state, [flag]: file.value })
    }


    const handleNextStep = async () => {
        setOpenBackDrop(true)
        const verificacion = new VeryfiCompDom(flujo.creditApplicationId)
        verificacion.setComprobante(state.comprobanteDomicilio)
        verificacion.setSelfiDomicilio(state.selfi_domicilio)
        try { 
            const send = isAltoPerfilBroker ? await verificacion.sendV2() : await verificacion.send()
            const sendSelfi = !isAltoPerfilBroker && await verificacion.sendSelfi()

            if(isAltoPerfilBroker) {
                if(send.status === 200 || send.status === 204) {
                    sessionStorage.setItem('flujo', JSON.stringify({ ...flujo, tubo: { ...flujo.tubo, VerifyAddressWithAutonomation: 'InProgress' } }))
                    navigate(pageContext?.next?.path)
                }
            } else {
                if ((send.status === 200 || send.status === 204) && (sendSelfi.status === 200 || sendSelfi.status === 204)) {
                    sessionStorage.setItem('flujo', JSON.stringify({ ...flujo, tubo: { ...flujo.tubo, VerifyAddressWithAutonomation: 'InProgress' } }))
                    navigate(pageContext?.next?.path)
                }
            }
        } catch (error) {
            setOpenBackDrop(false)
            setErrorService(true)
            console.error(error)
        }
    }
    return (
        <Grid container justify="center" >
            <Grid item xs={12} md={6} css={root}  >
                <Grid container css={styleTitle} justify="center">
                    <TitleIcon title="Comprobante de domicilio" icon={PublishIcon} textColor={true} />
                </Grid >
                <CardElevation>
                    <Box>
                        <Typography css={text}>
                            Necesitamos revisar tu comprobante de domicilio vigente (con fecha de al menos los últimos 3 meses)
                        </Typography>
                        <Box css={styleTitle}>
                            <FileUpload
                                id="verificacion-comprobante-comprobanteDomicilio-input"
                                accept=".png, .jpeg"
                                icon={InsertDriveFileIcon}
                                help='Si el comprobante está a tu nombre:
                                - Recibo de pago de servicio de cable-telefonía-internet (indispensable tener los 3 servicios en el mismo recibo) / Estado de cuenta bancario.

                                Si no está a tu nombre: 
                                - El recibo de teléfono fijo / Recibo de agua / Recibo de pago de luz / Recibo de gas'
                                label='Comprobante de domicilio'
                                helperText='Puedes subir archivos .jpeg o .png'
                                onChange={e => handleFile(e, 'comprobanteDomicilio')}
                                required
                            />
                        </Box>

                        {!isAltoPerfilBroker && <Box css={styleTitle}>
                            <Typography css={text}>
                                Favor de subir una selfi frente a la fachada actual de tu domicilio. Considera que debe ser visible el color de la fachada, la entrada o alguna referencia con la que podamos identificarla.
                            </Typography>
                            <FileUpload
                                id="verificacion-selfi_domicilio-input"
                                accept=".png, .jpeg"
                                icon={InsertDriveFileIcon}
                                help='Selfi domicilio'
                                label='Selfi domicilio'
                                helperText='Puedes subir archivos .jpeg o .png'
                                onChange={e => handleFile(e, 'selfi_domicilio')}
                                required
                            />
                        </Box>}

                        <Box css={btnContainer}>
                            <Button
                                id="verificacion-comprobante-nextStep-button"
                                variant="contained"
                                color="primary"
                                startIcon={<ArrowForwardIcon />}
                                css={rootBtn}
                                disabled={generarError}
                                onClick={handleNextStep}
                            >
                                Continuar
                            </Button>
                        </Box>
                    </Box>
                </CardElevation>
            </Grid>
            <Backdrop open={openBackDrop} className={classes.backdrop}>
                <CircularProgress color="primary" />
            </Backdrop>
            <Snackbar open={errorService} autoHideDuration={6000} onClose={() => setErrorService(false)}>
                <Alert variant="filled" severity="warning" onClose={() => setErrorService(false)}>
                    Lo sentimos, por el momento el servicio no se encuentra disponible
                </Alert>
            </Snackbar>
        </Grid>
    );
}

export default VerificacionComprobante;
